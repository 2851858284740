@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ojuju:wght@200..800&display=swap&text=0123456789');

* {
  margin: 0;
  padding: 0;
}


h1,
h2,
h3,
h4,
h5,
h6,
p, a {
  color: white;

}


h5{

}

body {
  background-color: #121212;
}

.h1_wrapper{
    display: flex;
    justify-content: center;
}

.h1_wrapper > *{
  font-family: "Cormorant Garamond", serif;
  font-size: 2.2rem;
}

.h1-separator::after {
  display: flex;
  justify-content: center;
  content: " ";
  margin-left:15%;
  width: 70%;
  height: 40px;
  background-size: cover;
  background-image: url("../src/Components/separator.png");
  background-position: center;
  background-repeat: no-repeat;
}

.centralizer{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.Vcentralizer{
  display: flex !important;
  align-items: center !important;
}

.modal-content{
  background-color: #121212 !important;
}

.modal-header >*, .modal-body{
  color: white !important;
}

.modal-header > button{
  background-color: white !important;
}

.dropdown-menu{
  background-color: #101010;
}

body::-webkit-scrollbar {
  width: 4px;               /* ширина scrollbar */
}

body::-webkit-scrollbar-thumb{
  border-radius: 20px;
  background-color: rgb(80, 80, 80);
}

body::-webkit-scrollbar-track {
  background: #121212;       /* цвет дорожки */
}

.mt-product-list{
  margin-top: 80px;
}

.pt-10{
  padding-top: 80px;
}

#basic-navbar-nav{
  display: none;
}

.show{
  display: block !important;
}

.cursor-pointer{
  cursor: pointer !important;
}