.todolist{
    list-style-type: none;
    padding-left: 20px;
}
.todolist > li{
    text-decoration: none;
    color: white;
    padding: 8px 0 8px 0;
}
.list_item_wrapper{
    display: flex;
}

.icon_wrapper{
    width: 35px;
    height: 35px;
}

.icon{
    width: 35px;
    height: 35px;
}

.list_item_text{
    padding-left: 20px;
    font-family: "Cormorant Garamond", serif;
    font-size: 1.3rem;
}

.btn_wrapper{
    display: flex;
    align-items: center;
    padding: 15px 0 15px 10px;
}


.anim_med{
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-name: pulce-med;
    animation-iteration-count: infinite;
}

.anim_police{
    animation-duration: 2s;
    animation-delay: 1s;
    animation-name: pulce-police;
    animation-iteration-count: infinite;
}

.anim_passport{
    animation-duration: 3s;
    animation-delay: 0s;
    animation-name: pulce-passport;
    animation-iteration-count: infinite;
}

.anim_human{
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-name: pulce-human;
    animation-iteration-count: infinite;
}


  
  @keyframes pulce-med{
    from {
      color: white;
    }

    50%{
        color:red;
    }
  
    to {
        color: white;
    }

  }

  @keyframes pulce-police {
    from {
      color: white;
    }

    50%{
        color:blue;
    }
  
    to {
        color: white;
    }

  }

  @keyframes pulce-passport{
    from {
      color: white;
    }

    50%{
        color:#002647;
    }
  
    to {
        color: white;
    }

  }

  @keyframes pulce-human {
    from {
      color: white;
    }

    50%{
        color:#0EF021;
    }
  
    to {
        color: white;
    }

  }