.link{
    padding: 10px 10px 10px 10px;
}

.centering_link{
    display: flex;
    align-items: center;
}

.set_my_color{
    font-family: "Cormorant Garamond", serif;
    font-size: 1.2rem;
    color:#D4B086 !important;
    border-color: #D4B086 !important; 
    background-color: transparent;
    text-decoration: none;
}

.set_my_color:hover, .set_my_color > *:hover{
    color:whitesmoke !important;
}

.set_my_color > *{
    color:#D4B086 !important;
}


.logo_title{
    padding-left: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.1rem;
}

.toggle:active{
    box-shadow: none !important;
}

.toggle:hover{
    border-width: 1px !important;
    box-shadow: none !important;
}

.navbar{
    background-color: #101010 !important;
}

.dropdown_bg_color{
    background-color: #101010 !important;
}

