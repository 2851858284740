.set_my_color{
    font-family: "Cormorant Garamond", serif;
    color:#D4B086 !important;
    border-color: #D4B086 !important; 
    text-decoration: none !important;
    font-size: 1.3rem;
}

.set_my_color > span{
    font-family: 'Times New Roman', Times, serif;
}

.set_my_color:hover, .set_my_color > *:hover{
    color:whitesmoke !important;
}

.set_my_color > *{
    color:#D4B086 !important;
}

.unp{
    cursor:default;
}

.unp:hover, .unp > span:hover{
    color:#D4B086 !important;
}


.logo_title{
    padding-left: 20px;
    font-family: "Cormorant Garamond", serif;
    font-size: 1.5rem !important;
}

.navbar{
    background-color: #181818 !important;
}

.modal > *{
    background-color: #181818 !important;
}

.politic{
    cursor: pointer;
}


