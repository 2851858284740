.contact_text_wrapper > p, a{
    font-size: 1.2rem;
}

.contact_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    background-color: rgb(14, 14, 14);
    border: 1px solid #d4b08663;
  }
  
  .contact_card{
    padding: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .icon_wrappper{
    display: flex;
    justify-content: center;
    padding: 20px 20px 40px 20px;
  }
  
  .icon_wrappper > i{
    font-size: 100px;
  }

  .links{
    display: block;
    text-decoration: none;
    padding-bottom: 10px;
  }

  .viber_icon{
    color: blueviolet;
  }

  .phone_icon{
    color: #44B104;
  }

  .email_icon{
    color: #0084F7;
  }

  .anim_viber{
    animation-duration: 2s;
    animation-name: pulce-viber;
    animation-iteration-count: infinite;
}

.anim_phone{
    animation-duration: 2s;
    animation-name: pulce-phone;
    animation-iteration-count: infinite;
}

.anim_email{
    animation-duration: 2s;
    animation-name: pulce-email;
    animation-iteration-count: infinite;
}
  
  @keyframes pulce-viber{
    from {color: white;}
    50% {color: blueviolet;}
    to {color: white;}
  }

  @keyframes pulce-phone{
    from {color: white;}
    50% {color: #44B104;}
    to {color: white;}
  }

  @keyframes pulce-email{
    from {color: white;}
    50% {color: #0084F7;}
    to {color: white;}
  }