.container{
    padding: 30px 20px 20px 20px;
    min-width: 290px;
    max-width: 350px;
    height: 520px;
    background-color: rgb(8,8,8);
    position: relative;
    border-radius: 20px;
    border: 1px solid #D4B086;
}

.container:hover{
    box-shadow: 0 0 10px whitesmoke;
}

.header{
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.7rem;
    font-family: "Cormorant Garamond", serif;
}

.header::after{
    position: absolute;
    height: 1px;
    width: 70%;
    content: ' ';
    bottom: 6px;
    left: 4%;
    background-color: #d4b08663;
}

.description{
    font-weight: 400;
    padding-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-family: "Cormorant Garamond", serif;
}

.price{
    display: block;
    position: absolute;
    bottom: 20px;
    font-weight: 700;
    font-size: 1.3rem;
}