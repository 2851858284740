.image{
    display: block;
    width: 100%;
    min-height: 400px;
}

.caption{
    top: 10% !important; 
}

.caption_header{
    font-size: 20px;
}

@media (max-width: 1024px) {
    .services_wrapper{
        display: flex !important;
        width: fit-content!important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        overflow-x: scroll !important;
        padding-bottom: 30px !important;
    }

    .item_wrapper{
        margin-left: 20px;
    }
}

@media (min-width: 1025px) {
    .services_wrapper{
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        width: 100% !important;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        justify-content:center !important;
    }
    .item_wrapper{
        width: 100%;
        display: flex !important;
        justify-content: center !important;
    }
}

@media (min-width: 1300px) {
    .services_wrapper{
        grid-template-columns: 1fr 1fr 1fr !important;
        width: 1300px !important;
    }
}
