.container{
    position: fixed;
    bottom: 30px;
    z-index: 999;
}

.link{
    border: 1px solid #D4B086;
    border-radius: 50%;
    padding: 20px;
    background-color: #121212;
    
}

.icon{
    color: #D4B086;
    animation: anim_phone 1s infinite;

}

@keyframes anim_phone {
    from{
        color: #D4B086;
    }
    50%{
        color: whitesmoke;
    }
    to{
        color: #D4B086;
    }
}