.container{
    position: relative;
    width: 300px;
    height: 100%;
    background-color: #161616;
    border: 1px solid #ffffff13;
    font-family: "Ojuju", "Cormorant Garamond", serif;

}

.title{
    color: whitesmoke;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 1.7rem;
}

.text{
    font-size: 1.2rem;
}


.price{
    color: whitesmoke;
    padding-top: 10px;
    padding-bottom: 30px;
}

.price > span{
    margin-left: 60px;
}