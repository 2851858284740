@media (max-width:576px){
    .BG{
        height: auto !important;
    }
    .text_wrapper{
        margin-top:4% !important;
    }
}

@media (min-width:576px) and (max-width:1200px){
    .BG{
        height: auto !important;
    }
    .text_wrapper{
        margin-top:4% !important;
    }
}

@media (min-width:1200px){
    .BG{
        height: 750px !important;
    }
    .text_wrapper{
        margin-top:10% !important;
    }
}


.BG{
    margin-top: 20px;
    width: 100%;
    max-height: 750px;
    background-position: center center;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../Home/homeBG.webp);
}

p{
    color: white;
}

.text_wrapper{
    margin-top:12%;
}

.text_wrapper > h1, .text_wrapper > p{
    font-family: "Cormorant Garamond", serif;
}

.text_wrapper > h1{
    font-size: 2.4rem;
    line-height: 2.3rem;
}

.text_wrapper > h1 > span{
    display: block;
    font-size: 2rem;
}

.text_wrapper > p{
    white-space: pre-wrap;
    padding-top: 0px;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    word-spacing: -0.2ch;
    letter-spacing: 1.1px;
    
}

.phone{
    display: block;
    margin-top: 10px;
    font-family: 'Times New Roman', Times, serif;
}


.pouring {
    display: block;
    font-size: 1.5rem;
    padding-top: 10px;
	background: linear-gradient(-45deg, whitesmoke,#D4B086, whitesmoke, #D4B086);
	background-size: 300%;
	font-weight: 900;
	text-transform: uppercase;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animated_text 4s ease-in-out infinite;
	-moz-animation: animated_text 4s ease-in-out infinite;
	-webkit-animation: animated_text 4s ease-in-out infinite;
}

.free{
    display: block;
    font-weight: bolder !important;
    padding-bottom: 20px;
    font-size: 1.45rem !important;
}

@keyframes animated_text {
	0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
}