.set_my_color{
    font-family: "Cormorant Garamond", serif;
    font-size: 1.2rem;
    color:whitesmoke !important;
    border-color: #D4B086 !important; 
    background-color: transparent;
    text-decoration: none;
}

.set_my_color:hover{
    color:#D4B086 !important;
    cursor: pointer;
}

.active_link{
    color: #D4B086 !important;
}
