.btn{
    font-family: "Cormorant Garamond", serif;
    text-decoration: none;
    border: 1px solid white;
    padding: 5px 30px 5px 30px;
    border-radius: 30px;
    font-size: 1.3rem;
    background-color: #121212;
    color:#D4B086 !important;
    border-color: #D4B086 !important;
}

.btn:hover{
    color:whitesmoke !important;
    border-color: whitesmoke !important;
    cursor: pointer;
}