
.list{
    list-style-type: none;
    max-width: 500px;
    padding-left: 25px;
}
.list > li{
    text-decoration: none;
    color: white;
    padding: 5px 0 5px 0;
}
.list_item_wrapper{
    display: flex;
}

.icon_wrapper{
    width: 16px;
    height: 16px;
}

.icon{
    rotate: 90deg;
    width: 16px;
    height: 16px;
}

.list_item_text{
    padding-left: 20px;
    font-family: "Cormorant Garamond", serif;
    font-size: 1.5rem;
    line-height: 94%;
}

.btn_wrapper{
    display: flex;
    align-items: center;
    padding: 15px 0 15px 10px;
}